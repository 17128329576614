// Variables
@import 'variables';
@import "common";

body{
    font-family: 'Nunito', sans-serif;
}

.quiz-bg{
    background-color: $body-quiz-bg;
}

/********************************************/
/*Custom for Song Chu Dong*/
/********************************************/

.container{
    max-width: 1140px !important;
}

.nav-link{
    color: $text-grey !important;
    font-size: 0.93rem;
    font-weight: 600;

    &.quiz-button{
        @include green-button;
        padding-left: 13px !important;
        padding-right: 13px !important;
    }

    &.active{
        border-bottom: 2px solid $primary-color;
    }
}

.navbar-toggler-icon{
    svg{
        fill: #e76f51;
    }
}

.stand-alone-quiz-button{
    @include green-button;
    text-decoration: none;
    padding: 15px 50px;
    &.quiz{
        padding: 12px 20px;
        border-radius: 7px;
    }

    &.search-btn{
        width:100%;
        @media screen and (min-width: 768px) {
            width: auto;
        }
    }
}

.stand-alone-blue-button{
    @include green-button;
    text-decoration: none;
    padding: 10px 30px;
}

.stand-alone-orange-button{
    @include orange-button;
    text-decoration: none;
    padding: 15px 20px;
    &.quiz{
        padding: 12px 20px;
    }

    @media screen and (min-width: 960px) {
        padding: 15px 50px;
    }

}

.nav-link:hover{
    color: $primary-color !important;
}

.nav-link.quiz-button:hover{
    color: #FFF !important;
    background-color: $primary-color;
}

.modal .modal-dialog{
    height: 93%;
}

.modal .modal-content{
    height: 100%;
}

.main-slider{
    // margin-top: -51px;
}

.tranh-thai-block{
    //width: 300px;
    background-color: #fff;
    box-shadow: 0 3px 10px #c6c6c6;
    border-radius: 12px;
    padding: 25px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__title{
        font-size: 1.1rem;
        font-weight: 700;
    }

    &.active{
        background-color: $primary-color;
    }

    &__cta{
        color: #667080;
        font-size: 16px;
        background-color: #EEF1F4;
    }
}

.circle-block{
    background-color: #FFF;
    border-radius: 250px;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-footer-link{
    font-size: 50px;
    color: #000;
    text-decoration: none;
}

.feature-video{
    background-color: $dark-blue;
    padding: 50px 0;
}

.splide__slide{
    height: auto; //200px;
    border-radius: 15px !important;
    img{
        border-radius: 15px !important;
    }
}

.song-chu-dong-list{
    display: flex;
    flex-direction: column;
    padding: 0;
    li{
        list-style: none;
    }
    &__item {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        align-items: center;
        text-decoration: none;
        color: #000;
        &:hover{
            color: $primary-color;
        }

        img{
            border-radius: 10px;
            max-width: 100%;
        }
        &__info{
            p{
                padding: 0;
                margin: 0;
            }
        }
    }
}

.focus-news{

    display: flex;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: 3px 5px 10px #c5c5c5;

    &__slider{
        display: block;
    }

    &__list{
        list-style: none;
        padding: 0;
        margin: 0;
        li:not(:last-child){
            margin-bottom: 15px;
        }
        &__item{
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            color: #000;
            &__info{
                font-size: 14px;
                font-weight: bold;
            }
            &__img{
                border-radius: 15px;
                overflow: hidden;
                /*height: 80px;
                width: 135px;*/
                background-color: #F00;
                max-width: 150px;
                max-height: 83px;
            }
        }
    }
}

.section_title{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    &__line{
        height: 5px;
        width: 70px;
        background-color: $primary-color;
    }
    &__text{
        font-size: 1.25rem;
    }
}

.footer-navigation-block{
    box-shadow: 0 -5px 5px #eee;
}

.tong-dai-suc-khoe-block{
    background-image: url("../images/tong-dai-suc-khoe.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 15px;

    .quiz-button{
        @include green-button;
        &.py-2{
            padding-top: 0.7rem !important;
            padding-bottom: 0.7rem !important;
        }
    }
}

.author-block{
    border-radius: 15px;
    background-color: $primary-light-color;
    &__avatar{
        border-radius: 100px;
        width: 100px;
        height: 100px;
    }
    &__info{
        &__name{
            font-size: 16px;
            font-weight: 700;
        }
        &__intro{
            font-size: 16px;
        }
    }
}

.latest-article-section{
    a{
        text-decoration: none;
        color: #000;
        &:hover{
            color: $primary-color;
        }
    }
}

.latest-article-item{
    box-shadow: 1px 1px 7px #ccc;
    border-radius: 15px;
    overflow: hidden;
    p{
        font-size: 16px;
        font-weight: 700;
    }
    &__photo{
        border-radius: 15px;
        overflow: hidden;
        max-height: 250px;
    }
}

.comment-block{
    &__item{
        border-radius: 15px;

        &__avatar{
            border-radius: 100px;
            width: 70px;
            height: 70px;
        }

        &__message{
            box-shadow: 5px 5px 5px #e9e9e9;
            border-radius: 15px;

            &__intro{

            }

            &__answer{
                background-color: #d2db4f80;
                padding: 10px 20px;
                border-radius: 30px;
                margin-top: 10px;
                font-weight: 600;
            }
        }
    }
}


.swiper.main-slider{
    width: 100%;
    height: 100%;

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}

.swiper.methodTranhThaiSwiper{
    width: 100%;
    height: 100%;

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        width: 38% !important;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}

.swiper.focusNewsImageSwiper{
    border-radius: 15px;
    overflow: hidden;
}

.focusNewsImageSwiper .swiper-slide{
    border-radius: 15px;
    overflow: hidden;
}

.focusNewsSwiper .swiper-slide {
    font-size: 18px;
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px;
}

.slick-prev, .slick-next{
    background: transparent;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    border: 1px solid #0074bf;
}
.slick-prev:before, .slick-next:before{
    line-height: 2.1;
    color: #0074bf;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
    background: #0074bf;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before{
    color: #FFF;
}

.quiz-header{
    height: 100px;
    p{
        font-size: 18px;
    }
}

.quiz-body{
    border-radius:  20px;
    margin-top: 72px;
    @media screen and (min-width: 768px) {
        margin-top: 110px;
    }
}

.quiz-banner{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 3px 3px 15px #a5a5a573;
}

.quiz-header-start-page{
    position: absolute;
    height: 32%;
    background-color: $primary-color;
    width: 100%;
    z-index: -1;
    @media screen and (min-width: 960px) {
        height: 40%;
    }
}

.quiz-option-img{
    max-height: 170px;
}

.quiz-question-bg{
    background-image: url("../images/quiz/question-frame-mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 250px;
    background-position: center;
    display: flex;
    align-items: center;
    padding-top: 35px;
    justify-content: center;

    &.large{
        height: 250px;
    }

    .question{
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;
    }
}


.swal2-html-container{
    text-align: left !important;
    font-size: 1rem;
}

.training-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    &__content_box{
        //width: 700px;
        // height: 260px;
        border: 1px solid #f5821e;
        border-radius: 20px;
        position: relative;
        &__quote{
            position: absolute;
            top: -20px;
            left: 50px;
            background-color: #FFF;
            padding: 0 10px;
            i{
                font-size: 40px;
                color: #f5821e;
            }

        }
    }
}

.swiper{
    width: 100%;
    height: auto;
}

.site-logo{
    width: 135px;
    @media screen and (min-width: 768px) {
        width: 200px;
    }
}

.navbar{
    background-color: #fff;
    box-shadow: 0 0 10px #eaeaea;
}

.navbar-brand{
    margin-right: 0;
}

.navbar-toggler{
    padding: 0;
    &:focus{
        box-shadow: none;
    }
}
.social-icon{
    width: 50px;
}
.social-icon a:hover svg{
    fill: #009688;
}

.search-icon{
    width: 25px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.search-icon svg{
    fill: #009688;
    flex: 0 0 auto;
    width: 25px;
}

.blog-img-content{
    width: 100%;
    height: auto;
}

@media all and (min-width: 640px) {
    .splide__slide{
        height: auto;//250px;
        margin: 10px !important;
    }

    .navbar-toggler{
        padding: 0.25rem 0.75rem;
    }

    .navbar-brand{
        margin-right: 1rem;
    }
}

@media all and (min-width: 992px) {

    /* .blog-img-content{
        width: auto;
    } */


    .navbar-collapse{
        flex-grow: unset;
    }

    .swiper.focusNewsImageSwiper{
        border-radius: 15px;
        overflow: hidden;
        width: 700px;
        height: 410px;
    }

    .song-chu-dong-list{
        &__item {
            img{
                border-radius: 10px;
                max-width: 250px;
            }
        }
    }

    .section_title{
        &__text{
            font-size: 1.575rem;
        }
        &__line{
            width: 100px;
        }
    }

    .quiz-question-bg{
        background-image: url("../images/quiz/question-frame.png") !important;
        height: 150px !important;
        &.large{
            height: 200px !important;
        }
    }

    .training-box{
        &__content_box{
            // height: 170px !important;
        }
    }

}

.article-created-at{
    font-size: 12px;
    font-style: italic;
    color: $primary-color;
    font-weight: 700;
}

.box-info{
    border: 1px solid #e3e3e3;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px #e6e6e6;
    border-left: 5px solid $primary-color;
    a{
        text-decoration: none;
        font-weight: 700;
        &:hover{
            color: $primary-color;
        }
    }
}

ol.breadcrumb, .table-content-article{
   padding: 0 ;
   li{
    list-style: none;
    a{
        text-decoration: none;
    }
   }
}

.table-content-article{
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #eaeaea;
}


.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    content : "" !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    content : "" !important;
}

.splide__arrow--prev {
    left: 0.5em !important;
}

.splide__arrow--next {
    right: 0.5em !important;
}

.splide__arrow svg{
    fill: #ffffff !important;
}

/********************************************/
/*End of custom for Song Chu Dong*/
/********************************************/

.nav-border-bottom{
    box-shadow: 0px 2px 0px #0c67a1;
}

.dropdown-menu li{
    list-style: none;
}

.dropdown-item{
    font-weight: 600;
}

.dropdown-item:hover{
    color: #ffffff !important;
    background-color: #0774b9;
}

.flex {
    display: flex;
}

.main-section-content{
    margin-top: 70px;
    @media screen and (min-width: 768px) {
        margin-top: $nav-height - 1;
    }

    &.home-page{
        margin-top: 55px;
    }
}


.main-section-content.quiz-layout{
    margin-top: $nav-height - 52;
}

.contact-title{
    border-bottom: 1px solid;
}

.footer-menu-section-title{
    font-size: 28px;
    font-weight: 700;
}


.footer-border-top{
    border-top: 1px solid $primary-color !important;
}

footer{
    a{
        color: #FFF;
        &:hover{
            color: #d2db4f;
        }
    }
}

footer .nav-link:hover{
    color: $primary-blue-color !important;
}

footer .nav-link{
    font-size: 0.85rem;
    font-weight: 400;
}

.term-link{
    color: #FFF;
    text-decoration: none;
    :hover{
        color: $primary-blue-color;
    }
}

.eb5-project-menu{
    color: #000;
    padding: 15px 0px 5px 0px;
    &__item{
        color: #000;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        :hover{
            color: $primary-color !important;
        }

        &__text{
            padding-left: 15px;
        }
    }
}

.dropdown-item.eb5-menu-wrapper:hover{
    background-color:transparent;
    color: #1e2125 !important;
}

.learn-more-button{
    background-color: #0595f1;
    box-shadow: 4px 5px 0 #0774b9;
}

.our-partner{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &__item{
        padding: 10px;
        &__link{
            text-decoration: none;
            height: 100px;
            justify-content: center;
            display: flex;
            img{
                align-self: center;
            }
        }
    }
}

.rounded-10{
    border-radius: 10px;
}

.middle-block{
    background-color: #00000087;
    border-radius: 12px;
    margin: 20px 0;
}

.red-bridge-bg{
    //background-image: url("https://free4kwallpapers.com/uploads/originals/2021/06/17/new-york-city-wallpaper.jpg");
    background-image: url("https://images.unsplash.com/photo-1541464522988-31b420f688b9");
    /*background-image: url("https://images.unsplash.com/photo-1521747116042-5a810fda9664");*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.feature-icon {
    width: 5rem;
    height: 5rem;
    border-radius: 0.75rem;
    background-image: linear-gradient(to bottom right, $primary-blue-color, $primary-color) !important;
    box-shadow: 5px 5px 10px #d4d4d4;
}

.bi {
    vertical-align: -0.125em;
    fill: currentColor;
}

.accordion-button{
    font-weight: 600;
    color: #333;
    background-color: #eee;
    border-radius: 10px;
}

.accordion-item{
    border: none;
    margin: 10px 0px;
}

.accordion-button:focus{
    box-shadow: none;
}

.accordion-button:not(.collapsed){
    color: $primary-color;
    box-shadow: none;
    border-radius: 6px;
    font-weight: 600;
}

.accordion-item:first-of-type .accordion-button{
    border-radius: 6px;
}

.accordion-item:last-of-type .accordion-button.collapsed{
    border-radius: 6px;
}

.accordion-body{
    padding: 1rem 1.25rem;
    background-color: #FFF;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid #eeeeee;
}

.card{
    border-radius: 0.8rem;
    border: none;
}

.card-img, .card-img-top{
    border-top-left-radius: calc(0.8rem - 1px);
    border-top-right-radius: calc(0.8rem - 1px);
}

.carousel-caption-custom h1{
    text-shadow:  0px 0px 5px #000;
    font-size: 1.5em;
}

